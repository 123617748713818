import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseFormMixins from '@/mixins/base-form-mixins';
import MainSelect from '@/components/UI/MainSelect.vue';
let BloodType = class BloodType extends BaseFormMixins {
    get options() {
        return this.$store.state.staticVariables.staticVariables.blood_groups;
    }
    get bloodType() {
        const currentGroupVal = this.$store.state.personalArea.medicalCard.blood_group;
        return { value: currentGroupVal, description: this.options.find(item => item.value === currentGroupVal)?.description };
    }
    bloodTypeChange({ value }) {
        this.$store.dispatch('personalArea/updateMedicalCardData', { blood_group: value });
    }
};
BloodType = __decorate([
    Component({
        components: {
            MainSelect,
        },
    })
], BloodType);
export default BloodType;
