import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import InputSuffix from '@/components/InputSuffix.vue';
import BaseFormMixins from '@/mixins/base-form-mixins';
;
import SaveFieldBtn from '@/components/SaveField.vue';
let Height = class Height extends BaseFormMixins {
    constructor() {
        super(...arguments);
        this.rules = [];
        this.oldValue = 0;
        this.height = this.$store.state.personalArea.medicalCard.height;
    }
    mounted() {
        this.oldValue = this.height;
        this.rules.push(this.inputRules.required);
    }
    get iconName() {
        return this.checkError(this.$refs.height) ? 'close-icon' : this.height ? 'check-icon' : null;
    }
    // Q-input has bug: prop maxlength cotflicts with type-prop
    // get height(): number {
    //   return this.$store.state.personalArea.medicalCard.height;
    // }
    // set height(value: number) {
    //   this.$store.commit('personalArea/setMedicalCardProperty', {
    //     name: 'height',
    //     value,
    //   });
    // }
    async saveData() {
        if (!this.$refs.height.validate())
            return;
        await this.$store.dispatch('personalArea/updateMedicalCardData', { height: this.height });
        this.oldValue = this.height;
    }
};
Height = __decorate([
    Component({
        components: {
            InputSuffix,
            SaveFieldBtn,
        },
    })
], Height);
export default Height;
