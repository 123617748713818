import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import ReactionCard from '@/components/ReactionCard.vue';
import BaseFormMixins from '@/mixins/base-form-mixins';
let ComponentWithAddReaction = class ComponentWithAddReaction extends BaseFormMixins {
    constructor() {
        super(...arguments);
        this.rules = [];
        this.oldValue = [];
    }
    checkOldValue(id, title, reaction) {
        const data = this.oldValue.find((item) => item.id === id);
        return data?.title !== title || data?.reaction !== reaction;
    }
    mounted() {
        this.rules.push(this.inputRules.required);
        this.oldValue = this.value.map((item) => Object.assign({}, item));
    }
    addField() {
        this.$store.dispatch('personalArea/addReactionItem', {
            endpoint: this.data.endPoint,
            reactionsData: this.getReactionsData(),
        });
    }
    getReactionsData() {
        return {
            title: null,
            reaction: null,
        };
    }
    async save(item) {
        await this.$store.dispatch('personalArea/updateReactionsItem', {
            id: item.id,
            endpoint: this.data.endPoint,
            item: this.getItemById(item.id),
        });
        item.new = false;
        this.oldValue = this.value.map((item) => Object.assign({}, item));
    }
    getItemById(id) {
        return this.value.find((item) => item.id === id);
    }
    deleteField(id) {
        this.$store.dispatch('personalArea/deleteReactionItem', { id, endpoint: this.data.endPoint });
    }
};
__decorate([
    Prop()
], ComponentWithAddReaction.prototype, "data", void 0);
__decorate([
    Prop()
], ComponentWithAddReaction.prototype, "value", void 0);
__decorate([
    Prop({ default: true })
], ComponentWithAddReaction.prototype, "isReactionVisible", void 0);
ComponentWithAddReaction = __decorate([
    Component({
        components: {
            ReactionCard
        },
    })
], ComponentWithAddReaction);
export default ComponentWithAddReaction;
