import { render, staticRenderFns } from "./Weight.vue?vue&type=template&id=74d4776c&"
import script from "./Weight.vue?vue&type=script&lang=ts&"
export * from "./Weight.vue?vue&type=script&lang=ts&"
import style0 from "./Weight.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QField});
