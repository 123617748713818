<template>

        <div class="reaction__item">
          <div v-if="showEditMode" class="reaction__item-title">Добавить новый препарат</div>
          <div v-if="showEditMode" class="form-input reaction__item-input">
            <q-input
              v-model.trim="item.title"
               :rules="rules"

              :placeholder="data.inputPlaceholder"
            >
              <!-- <q-btn class="reaction__item-delete" @click="$emit('delete')">
                <icon v-slot:prepend name="delete-icon"></icon>
              </q-btn> -->

              <!-- <SaveFieldBtn
                :show="item.title && item.reaction && $emit('check')"
                v-slot:append
                @save="$emit('save')"
              /> -->
            </q-input>
          </div>
          <div v-if="showEditMode" class="reaction__item-area form-input form-input--area">
            <q-input
              :rules="rules"
              type="textarea"
              v-model.trim="item.reaction"
              ref="reaction"
              :placeholder="data.areaPlaceholder"
            />
          </div>
          <div  v-if="showEditMode" class="row">
            <button @click.prevent="save" :disabled="!item.title || !item.reaction"  class="btn-temp" >Сохранить</button>
            <button @click.prevent="$emit('delete')" class="btn-temp _transparent">Отменить</button>
          </div>
        <div v-if="!showEditMode" class="reaction__card">
          <div class="reaction__card-title">{{item.title}}</div>
          <div class="reaction__card-descr">{{item.reaction}}</div>
          <div class="reaction__card-footer">
            <BaseBtn 
              class="reaction__card-edit" 
              icon="edit-icon" :iconSize="12" 
              type="outline" size="sm" @click="editMode = true">Редактировать</BaseBtn>
            <BaseBtn 
              icon="delete-icon" :iconSize="12" 
              type="outline" size="sm" @click="$emit('delete')"></BaseBtn>
          </div>

        </div>
        </div>


</template>

<script>
// import SaveFieldBtn from '@/components/SaveField.vue';
// TODO refact
export default {
  // components: {SaveFieldBtn},
  data(){
    return {
      editMode: false
    }
  },
  props: ['item', 'data', 'rules'],
  computed: {
    showEditMode(){
      return this.item.new || this.editMode
    }
  },
  methods: {
    save(){
      this.$emit('save')
      this.editMode = false
    }
  }
}
</script>


<style lang="scss" >
.reaction__item .q-field--error input,  
.reaction__item .q-field--error textarea  {
  border: 1px solid #FF7C7C !important
}
.reaction__item input,  
.reaction__item textarea  {
  box-shadow: none !important;
}

.reaction__card-footer {
  display: flex;
  gap: 4px;
  width: 100%;
  margin-top: 18px;
}
.reaction__card-edit {
  flex-grow: 1
}
</style>