import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseFormMixins from '@/mixins/base-form-mixins';
import InputSuffix from '@/components/InputSuffix.vue';
import SaveFieldBtn from '@/components/SaveField.vue';
let Weight = class Weight extends BaseFormMixins {
    constructor() {
        super(...arguments);
        this.rules = [];
        this.oldValue = 0;
    }
    mounted() {
        this.rules.push(this.inputRules.required);
        this.oldValue = this.weight;
    }
    get iconName() {
        return this.checkError(this.$refs.weight) ? 'close-icon' : this.weight ? 'check-icon' : null;
    }
    get weight() {
        return this.$store.state.personalArea.medicalCard.weight;
    }
    set weight(value) {
        this.$store.commit('personalArea/setMedicalCardProperty', {
            name: 'weight',
            value,
        });
    }
    async save() {
        await this.$store.dispatch('personalArea/updateMedicalCardData', { weight: this.weight });
        this.oldValue = this.weight;
    }
};
Weight = __decorate([
    Component({
        components: {
            InputSuffix,
            SaveFieldBtn,
        },
    })
], Weight);
export default Weight;
