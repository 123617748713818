import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import Pregnancy from '@/views/PersonalArea/medicalCard/Pregnancy.vue';
import MenstrualCycles from '@/views/PersonalArea/medicalCard/MenstrualCycles.vue';
import ComponentWithAddReaction from '@/components/ComponentWithAddReaction.vue';
import BiologicalSex from '@/views/PersonalArea/medicalCard/BiologicalSex.vue';
import Race from '@/views/PersonalArea/medicalCard/Race.vue';
import Nationality from '@/views/PersonalArea/medicalCard/Nationality.vue';
import RegionOfResidence from '@/views/PersonalArea/medicalCard/RegionOfResidence.vue';
import Height from '@/views/PersonalArea/medicalCard/Height.vue';
import BloodType from '@/views/PersonalArea/medicalCard/BloodType.vue';
import Weight from '@/views/PersonalArea/medicalCard/Weight.vue';
import Birthday from '@/views/PersonalArea/medicalCard/Birthday.vue';
import { IRouter } from '@/interfaces/router.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
import BasePageHeader from '@/components/BasePageHeader.vue';
import { hormonalDrugsData, allergicReaction } from '@/utils/utils';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
let MedicalCard = class MedicalCard extends Vue {
    constructor() {
        super(...arguments);
        this.showUserMustSetPersonalDataModal = false;
    }
    get userMustSetPersonalData() {
        return this.$store.state.personalArea.userMustSetPersonalData;
    }
    setShowUserMustSetPersonalDataModal() {
        if (this.userMustSetPersonalData) {
            this.showUserMustSetPersonalDataModal = true;
        }
    }
    get userSettedPersonalData() {
        const { gender, birth_date } = this.$store.state.personalArea.medicalCard;
        return this.userMustSetPersonalData && gender && birth_date;
    }
    watchUserSettedPersonalData(newValue) {
        if (newValue) {
            this.$store.state.personalArea.userMustSetPersonalData = false;
        }
    }
    get hormonalDrugsData() {
        return hormonalDrugsData;
    }
    get allergicReaction() {
        return allergicReaction;
    }
    get hormonalDrugs() {
        return this.$store.state.personalArea.medicalCard.hormonal_drugs;
    }
    get allergicReactions() {
        return this.$store.state.personalArea.medicalCard.allergies;
    }
    get isMedicalCardPage() {
        return this.$route.name === ROUTE_NAME.MEDICAL_CARD_PAGE;
    }
    get labstoryPatient() {
        return this.$store.state.personalArea.patient.from_jlab;
    }
};
__decorate([
    Watch('userMustSetPersonalData', { immediate: true })
], MedicalCard.prototype, "setShowUserMustSetPersonalDataModal", null);
__decorate([
    Watch('userSettedPersonalData')
], MedicalCard.prototype, "watchUserSettedPersonalData", null);
MedicalCard = __decorate([
    Component({
        components: {
            Pregnancy,
            MenstrualCycles,
            ComponentWithAddReaction,
            BiologicalSex,
            Race,
            Nationality,
            RegionOfResidence,
            Height,
            BloodType,
            Weight,
            Birthday,
            BasePageHeader,
            MessageModal
        },
    })
], MedicalCard);
export default MedicalCard;
