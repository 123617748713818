import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseFormMixins from '@/mixins/base-form-mixins';
let BiologicalSex = class BiologicalSex extends BaseFormMixins {
    get genders() {
        return this.$store.state.staticVariables?.staticVariables?.genders?.map((item) => {
            return {
                label: item.description,
                value: item.value,
            };
        });
    }
    get biologicalSex() {
        return this.$store.state.personalArea.medicalCard.gender;
    }
    set biologicalSex(value) {
        this.$store.dispatch('personalArea/updateMedicalCardData', { gender: value });
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], BiologicalSex.prototype, "hideTitle", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], BiologicalSex.prototype, "disableBtnToggle", void 0);
BiologicalSex = __decorate([
    Component({})
], BiologicalSex);
export default BiologicalSex;
