import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let InputSuffix = class InputSuffix extends Vue {
    getTextWidth(text, font) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (context) {
            context.font = font;
            const metrics = context.measureText(text);
            return metrics.width;
        }
    }
};
__decorate([
    Prop({ default: '' })
], InputSuffix.prototype, "suffixText", void 0);
__decorate([
    Prop({ default: '' })
], InputSuffix.prototype, "inputText", void 0);
__decorate([
    Prop({ default: 0 })
], InputSuffix.prototype, "baseOffset", void 0);
__decorate([
    Prop({ default: '14px inter' })
], InputSuffix.prototype, "suffixStyle", void 0);
__decorate([
    Prop()
], InputSuffix.prototype, "inputFontStyle", void 0);
__decorate([
    Prop({ default: 'left' })
], InputSuffix.prototype, "suffixPosition", void 0);
InputSuffix = __decorate([
    Component({})
], InputSuffix);
export default InputSuffix;
