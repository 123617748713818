import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import InputDate from '@/components/InputDate.vue';
import { format } from 'date-fns';
import { serverDateFormat } from '@/interfaces/api.interface';
let Birthday = class Birthday extends Vue {
    get birthday() {
        return this.$store.state.personalArea.medicalCard.birth_date;
    }
    changeBirthday(value) {
        this.$store.dispatch('personalArea/updateMedicalCardData', {
            birth_date: format(new Date(value), serverDateFormat),
        });
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], Birthday.prototype, "disableDatePicker", void 0);
Birthday = __decorate([
    Component({
        components: { InputDate },
    })
], Birthday);
export default Birthday;
