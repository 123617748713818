import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let RegionOfResidence = class RegionOfResidence extends Vue {
    get regionTypes() {
        return this.$store.state.staticVariables.staticVariables?.region_types?.map((item) => {
            return {
                label: item.description,
                value: item.value,
            };
        });
    }
    get regionOfResidence() {
        return this.$store.state.personalArea.medicalCard.region_type;
    }
    set regionOfResidence(value) {
        this.$store.dispatch('personalArea/updateMedicalCardData', { region_type: value });
    }
};
RegionOfResidence = __decorate([
    Component({})
], RegionOfResidence);
export default RegionOfResidence;
