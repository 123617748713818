import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
let BasePageHeader = class BasePageHeader extends Vue {
    goBack() {
        this.$router.go(-1);
    }
};
__decorate([
    Prop()
], BasePageHeader.prototype, "title", void 0);
BasePageHeader = __decorate([
    Component({
        components: {
            BackBtn,
        },
    })
], BasePageHeader);
export default BasePageHeader;
