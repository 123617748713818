import { render, staticRenderFns } from "./MedicalCard.vue?vue&type=template&id=287729df&"
import script from "./MedicalCard.vue?vue&type=script&lang=ts&"
export * from "./MedicalCard.vue?vue&type=script&lang=ts&"
import style0 from "./MedicalCard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QExpansionItem,QCard,QCardSection,QForm,QItem,QBtn,QBtnGroup});
