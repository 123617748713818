import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import BaseFormMixins from '@/mixins/base-form-mixins';
import MainSelect from '@/components/UI/MainSelect.vue';
let Race = class Race extends Vue {
    get options() {
        return this.$store.state.staticVariables.staticVariables?.races;
    }
    get race() {
        return this.$store.state.personalArea.medicalCard.race;
    }
    set race({ value }) {
        this.$store.dispatch('personalArea/updateMedicalCardData', { race: value });
    }
    raceChange({ value }) {
        this.$store.dispatch('personalArea/updateMedicalCardData', { race: value });
    }
};
Race = __decorate([
    Component({
        mixins: [BaseFormMixins],
        components: {
            MainSelect,
        },
    })
], Race);
export default Race;
